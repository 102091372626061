import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import javascript from 'highlight.js/lib/languages/javascript';
import bash from 'highlight.js/lib/languages/bash';
import shell from 'highlight.js/lib/languages/shell';
import java from 'highlight.js/lib/languages/java';
import kotlin from 'highlight.js/lib/languages/kotlin';
import swift from 'highlight.js/lib/languages/swift';
import objectivec from 'highlight.js/lib/languages/objectivec';
import toml from 'highlight.js/lib/languages/ini';
import ada from 'highlight.js/lib/languages/ada';
import pony from 'highlight.js/lib/languages/pony';
import reasonml from 'highlight.js/lib/languages/reasonml';

import idris from './utils/highlight-idris';
import { Button } from '../../components/styled';
import { Link } from '../../components';
import {
  Author,
  BlogLayout,
  RelatedPosts,
  ShareIcons,
} from '../../components/blog';
import useHttps from '../../utils/use-https';

hljs.registerLanguage('json', json);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('shell', shell);
hljs.registerLanguage('java', java);
hljs.registerLanguage('kotlin', kotlin);
hljs.registerLanguage('swift', swift);
hljs.registerLanguage('objectivec', objectivec);
hljs.registerLanguage('toml', toml);
hljs.registerLanguage('ada', ada);
hljs.registerLanguage('pony', pony);
hljs.registerLanguage('reasonml', reasonml);
hljs.registerLanguage('idris', idris);

const BlogPost: React.FC<{ data: any }> = ({ data }) => {
  const post = data.ghostPost;

  useEffect(() => {
    document.querySelectorAll('pre code').forEach(block => {
      hljs.highlightBlock(block as any);
    });

    const colour = post.codeinjection_foot;
    if (colour) {
      const style = document.createElement('style');
      style.innerHTML = `
        .blog-post a {
          border-bottom-color: ${colour} !important;
        }

        .blog-post a:not(.button):hover,
        .blog-post a:not(.button):focus {
          color: ${colour} !important;
        }
      `;
      document.body.appendChild(style);
    }

    const firstParagraph = document.querySelector(
      '.blog-post > section p:first-of-type',
    ) as any;
    if (firstParagraph?.style) firstParagraph.style.position = 'relative';

    const withLinks = (
      <>
        <span dangerouslySetInnerHTML={{ __html: firstParagraph?.innerHTML }} />
        <ShareIcons {...post} />
      </>
    );
    ReactDOM.render(withLinks, firstParagraph);
  }, []);

  const relatedPosts = data.allGhostPost.edges
    .map(relatedPost => relatedPost.node)
    .filter(
      relatedPost =>
        ![post.ghostId, post.id].includes(relatedPost.ghostId) &&
        post.primary_tag &&
        relatedPost.tags.some(t => t.slug === post.primary_tag.slug),
    )
    .slice(0, 3);

  return (
    <BlogLayout
      title={post.title}
      seoPrefix="Fidel in Focus – "
      seoTitle={post.twitter_title || post.title}
      seoImage={useHttps(post.twitter_image || post.feature_image)}
      seoDescription={post.twitter_description || post.custom_excerpt}
    >
      <article className="blog-post">
        <time className="post-timestamp">
          {dayjs(post.published_at).format('MMM D, YYYY')}
        </time>
        <h1 className="post-title">{post.title}</h1>
        <section dangerouslySetInnerHTML={{ __html: useHttps(post.html) }} />
        <footer className="post-footer">
          {post.primary_author && <AuthorLink author={post.primary_author} />}
        </footer>
      </article>
      {post.primary_tag && <RelatedPosts posts={relatedPosts} />}
    </BlogLayout>
  );
};

const AuthorLink: React.FC<{ author: any }> = ({ author }) => (
  <Link to={`/blog/author/${author.slug}`} className="post-author-link">
    <Author {...author} withImage />
    <Button variant="secondary" type="button">
      All author posts
    </Button>
  </Link>
);

export const query = graphql`
  query ($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...BlogPost
      published_at
    }

    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          ...BlogPostShort
          twitter_image
        }
      }
    }
  }
`;

export default BlogPost;
